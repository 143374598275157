import axios from "axios";

const CLOUDFUNCTION_API =
  "https://europe-west3-innoplexia-ivi.cloudfunctions.net/openai-fetcher-public";

const api = {
  fetchResults: (userInput) =>
    axios.post(`${CLOUDFUNCTION_API}`, {
      message: userInput ?? "",
    }),
};

export default api;
