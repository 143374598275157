import "./App.css";
import "@snpjs/core/lib/css/snpkit.css";
import "@snpjs/icons/lib/css/snpkit-icons.css";
import "@snpjs/crystalbridge/lib/css/snpkit-crystalbridge.css";
import { Button, TextArea } from "@snpjs/core";
import React from "react";
import ResultDisplay from "./ResultDisplay";
import logoSource from "./assets/images/snp-logo-blue.svg";
import api from "./api";
import { CrystalBridgeFooter } from "@snpjs/crystalbridge";

function App() {
  const [inputValue, setInputValue] = React.useState();
  const [requestStatus, setRequestStatus] = React.useState(null);
  const [resultValue, setResultValue] = React.useState(null);

  const updateInputValue = React.useCallback(
    (event) => setInputValue(event.target.value),
    [setInputValue]
  );

  const onSubmit = React.useCallback(() => {
    setRequestStatus("LOADING");
    api
      .fetchResults(inputValue)
      .then((response) => {
        if (response.data.error) {
          setRequestStatus("ERROR");
          setResultValue(response.data.error.message);
        } else {
          setRequestStatus("FINISHED");
          setResultValue(response.data.choices[0].message.content);
        }
      })
      .catch((error) => {
        setRequestStatus("ERROR");
        setResultValue(error.message);
      });
  }, [inputValue]);

  return (
    <div className="app">
      <div className="app-container">
        <header className="app-header">
          <img width={80} alt="SNP Logo" src={logoSource} />
          <div className="header-title">
            <h2>SNP Knowledge Article Assistant</h2>
          </div>
        </header>
        <div className="app-body">
          <div style={{ minWidth: "60%" }}>
            <TextArea
              large
              intent="primary"
              autoResize
              value={inputValue}
              onChange={updateInputValue}
              fill
              placeholder="Please paste your article in this textarea."
            />
          </div>
          <div style={{ margin: "15px" }}>
            <Button type="submit" onClick={onSubmit} intent="primary">
              Submit
            </Button>
          </div>
          <ResultDisplay value={resultValue} status={requestStatus} />
        </div>
        <footer className="content-footer">
          <CrystalBridgeFooter />
        </footer>
      </div>
    </div>
  );
}

export default App;
