import { TextArea } from "@snpjs/core";
import React from "react";
import LoadingIndicator from "./LoadingIndicator";
import ErrorMessage from "./ErrorMessage";

export default function ResultDisplay(props) {
  switch (props.status) {
    case "LOADING":
      return <LoadingIndicator />;
    case "ERROR":
      return <ErrorMessage message={props.value} />;
    case "FINISHED":
      return <TextArea readOnly value={props.value} autoResize fill />;
    default:
      return null;
  }
}
