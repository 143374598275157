import { Spinner } from "@snpjs/core";
import React from "react";

export default function LoadingIndicator() {
  return (
    <React.Fragment>
      <Spinner />
    </React.Fragment>
  );
}
