import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import LoginPage from "./LoginPage";

export default function Root() {
  //  return <Routes>
  //     {/* <Route index element={<LoginPage />} /> */}
  //     <Route index element={<App />} />
  //  </Routes>
  return <App />;
}
